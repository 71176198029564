// Entry point for the build script in your package.json
import "@hotwired/turbo-rails"
import "./controllers"

import "jquery"
import * as bootstrap from "bootstrap"
import * as jasny from 'jasny-bootstrap'
import "admin-lte"

import Rails from "@rails/ujs"
Rails.start()

// Custom
import "./custom/bookmarks"
import "./custom/datagrid"
import "./helpers/helpers"
import "trix"
import "@rails/actiontext"
import "tinymce"
