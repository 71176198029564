import { Application } from "@hotwired/stimulus"
import jQuery from "jquery"
import { Turbo } from "@hotwired/turbo-rails"

const application = Application.start()


// Configure Stimulus development experience
application.debug = false
Turbo.session.drive = false
window.Stimulus   = application
window.jQuery = window.$ = jQuery

$(function () {
    $('[data-toggle="tooltip"]').tooltip();
    $('[data-toggle="popover"]').popover();
})

export { application }
