window.init_bookmarking = function() {
    var add_bookmark_click, remove_bookmark_click;
    $(".toggle_bookmark.text-default").click(function() {
        return add_bookmark_click($(this));
    });
    $(".toggle_bookmark.text-warning").click(function() {
        return remove_bookmark_click($(this));
    });
    add_bookmark_click = function(elem) {
        elem.unbind("click");
        return $.ajax({
            url: "/bookmarks/create_bookmark",
            type: "POST",
            data: {
                object_id: elem.data('object-id'),
                object_type: elem.data('object-type')
            },
            success: function(data) {
                $(".toggle_bookmark[data-object-id=" + data.bookmarkable_id + "]").removeClass("text-default").addClass("text-warning");
                $(".toggle_bookmark[data-object-id=" + data.bookmarkable_id + "]").find("i").removeClass("far").addClass("fas");
                elem.attr('data-original-title', I18n.t("bookmarks.remove_bookmark")).tooltip('show');
                return elem.click(function() {
                    return remove_bookmark_click(elem);
                });
            }
        });
    };
    return remove_bookmark_click = function(elem) {
        elem.unbind("click");
        return $.ajax({
            url: "/bookmarks/destroy_bookmark",
            type: "POST",
            beforeSend: function(xhr) {xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'))},
            data: {
                object_id: elem.data('object-id'),
                object_type: elem.data('object-type')
            },
            success: function(data) {
                $(".toggle_bookmark[data-object-id=" + data.bookmarkable_id + "]").removeClass("text-warning").addClass("text-default");
                $(".toggle_bookmark[data-object-id=" + data.bookmarkable_id + "]").find("i").removeClass("fas").addClass("far");
                elem.attr('data-original-title', I18n.t("bookmarks.add_bookmark")).tooltip('show');
                return elem.click(function() {
                    return add_bookmark_click(elem);
                });
            }
        });
    };
};

$(document).ready(init_bookmarking);
