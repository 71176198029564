function filter_events() {
    var removeURLParameter;
    $(".filter_tag a").click(function() {
        var brackets;
        brackets = $(this).data("isArray") ? "[]" : "";
        return window.location = removeURLParameter(window.location.href + "&delete_session_filters=true", ($(this).data("grid")) + "[" + ($(this).data("attribute")) + "]" + brackets);
    });
    return removeURLParameter = function(url, parameter) {
        var i, pars, prefix, urlparts;
        urlparts = url.split('?');
        if (urlparts.length >= 2) {
            prefix = encodeURIComponent(parameter) + '=';
            pars = urlparts[1].split(/[&;]/g);
            i = pars.length;
            while (i-- > 0) {
                if (pars[i].lastIndexOf(prefix, 0) !== -1) {
                    pars.splice(i, 1);
                }
            }
            url = urlparts[0] + (pars.length > 0 ? '?' + pars.join('&') : '');
            return url;
        } else {
            return url;
        }
    };
};

$(document).ready(filter_events);

function init_pages_bookmarking() {
    var remove_bookmark_page_click;
    $(".submit-form-add-custom-bookmark").click(function() {
        var elem;
        $("#modal_event_div").removeAttr("data-toggle");
        $("#modal_event_div").removeAttr("data-target");
        $("#modal_custom_name_bookmark").modal("hide");
        elem = $(".toggle_bookmark_page.text-default");
        elem.unbind("click");
        elem.removeClass("text-default").addClass("text-warning");
        elem.find("i").removeClass("far").addClass("fas");
        elem.attr('data-original-title', I18n.t("pages.remove_bookmark"));
        return elem.click(function() {
            return remove_bookmark_page_click(elem);
        });
    });
    $(".toggle_bookmark_page.text-warning").click(function() {
        return remove_bookmark_page_click($(this));
    });
    remove_bookmark_page_click = function(elem) {
        elem.unbind("click");
        return $.ajax({
            url: "/bookmarks/destroy_page",
            type: "POST",
            beforeSend: function(xhr) {xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'))},
            data: {
                original_fullpath: elem.data('original-fullpath')
            },
            success: function(data) {
                $("#modal_event_div").attr("data-toggle", "modal");
                $("#modal_event_div").attr("data-target", "#modal_custom_name_bookmark");
                elem.removeClass("text-warning").addClass("text-default");
                elem.find("i").removeClass("fas").addClass("far");
                return elem.attr('data-original-title', I18n.t("pages.add_bookmark")).tooltip('show');
            }
        });
    };
    return $("#custom_name_input").keyup(function() {
        if (($(this).val().length) >= 3) {
            $('.submit-form-add-custom-bookmark').removeAttr('disabled');
        } else {
            $('.submit-form-add-custom-bookmark').prop("disabled", true);
        }
    });
};

$(document).ready(init_pages_bookmarking);


window.compute_filter_counters = function compute_filter_counters(bookmarks_count) {
    for (var i = 0; i <= bookmarks_count; i++) {
        var target = $('.bookmark-counts-' + i),
            full_path = target.data('fullpath');
        if (full_path != undefined) {
            ajax_count_call(target, full_path);
        }
    }

    function ajax_count_call(target, full_path) {
        $.ajax({
            url : full_path,
            type : 'GET',
            dataType : 'json',
            success : function(data){
                var badge_class;
                if (parseInt(data) > 0) {
                    badge_class = ' badge-primary';
                } else {
                    badge_class = ' badge-danger';
                }

                var link = '<a href="' + full_path + '" class="badge' + badge_class + '">' + data + '</a>';
                target.html(link);
            }
        });
    }
}

// How to force reloading a page when using browser back button?
// You can use pageshow event to handle situation when browser navigates to your page through history traversal:
// https://stackoverflow.com/questions/43043113/how-to-force-reloading-a-page-when-using-browser-back-button
window.addEventListener( "pageshow", function ( event ) {
    var historyTraversal = event.persisted ||
        ( typeof window.performance != "undefined" &&
        window.performance.navigation.type === 2 );
    if ( historyTraversal ) {
        // Handle page restore.
        window.location.reload();
    }
});
